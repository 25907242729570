import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnDetailInner0013 from '../../../components/molecules/columnDetailInner0013'
import ColumnRelationCards0013 from '../../../components/molecules/columnRelationCards0013'
import UsefulFunctionLayout017 from '../../../components/molecules/usefulFunctionLayout017'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0013 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '36協定とは？「知らなかった」では済まされない、押さえておきたい基礎知識を解説！',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="残業に必要な36協定、「知らなかった」では済まされない基礎知識"
        description="残業等が想定される場合、労使協定を交わすことで法定労働時間を超えて労働させることができる、これが36協定です。ただし、時間には上限がありますので注意しましょう。"
        ogUrl="https://kintaicloud.jp/column/details/C0013/"
        ogType="article"
        ogTitle="残業に必要な36協定、「知らなかった」では済まされない基礎知識"
        ogDescription="残業等が想定される場合、労使協定を交わすことで法定労働時間を超えて労働させることができる、これが36協定です。ただし、時間には上限がありますので注意しましょう。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0013.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0013 />
        {/** オーサ情報
        <ColumnAuthor003 /> */}
        {/** お役立ち機能 */}
        <UsefulFunctionLayout017 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0013 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0013

export const pageQuery = graphql`
  query C0013 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
