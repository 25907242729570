import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      &.indent-back {
        text-indent: -1em;
        padding-left: 2em;
      }
      &.bottom-none {
        padding-bottom: 0;
      }
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
        &.bold {
          background: none;
          font-size: 22px;
          padding-right: 10px;
        }
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
      &.img-graph {
        padding-bottom: 10px;
      }
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0013 = () => (
  <Wrapper>
    <ColumnH1 label="残業に必要な36協定、「知らなかった」では済まされない基礎知識" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        社会には様々な業種があり、業務内容等会社によって多様です。ですが、ほとんどの企業では繁忙期など、通常の労働時間では業務が間に合わない場合に
        <span>「残業（時間外労働）」</span>がある事が共通しています。
        <br />
        ここでは、残業を行うために必要なことについて解説していきます。
      </p>
      <img src="/images/column/details/c0013.jpg" alt="キンクラコラム画像13" />
      <h1>労働者に残業をしてもらうには、労使協定が必要</h1>
      <p>
        現在の労働基準法では、労働時間は原則
        <span>「1日8時間」「週40時間」</span>
        という法定労働時間が定められており、法定労働時間を超えて労働させてはならないこととなっています。
        <br />
        しかし、繁忙期等においては法定労働時間の範囲内で収まらないケースがあり、残業等が想定される場合に
        <span>労使協定を交わす</span>
        ことで法定労働時間を超えて、労働者に労働させることができます。これが36協定です。
      </p>
      <h1>36協定とは</h1>
      <p>
        36協定とは、労働基準法第36条で時間外及び休日の労働が定められている協定の事を指します。
        <br />
        時間外労働等が想定される事業場ごとに、「1か月」「1年」それぞれの時間外労働の限度を定め、休日労働が想定される場合は休日労働についての定めも必要です。
        <br />
        36協定は、会社が一方的に定めることは不可です。
        <span>労働組合又は事業場労働者の過半数の代表者との労使協定が必要</span>
        です。（過半数労働者代表の選出についての注意点は後述します）
        <br />
        36協定を有効にするには、会社と労働者代表等との署名又は押印がある「協定書」、様式9号等の「協定届」を作成します。そして作成した「協定届」を管轄労働基準監督署へ提出し、有効となります。
      </p>
      <h1>時間外労働（残業）等には条件がある</h1>
      <p>
        前項で「1か月」「1年」の限度時間を定める、と記載しました。この会社が定められる限度時間には
        <span>法定の上限</span>
        があります。以下の範囲で時間外労働をさせる時間を定めます。
        <br />
        <br />
        ・「1か月」の限度時間は「45時間（1年変形労働時間制適用は42時間）」
        <br />
        ・「1年」の限度時間は「360時間（1年変形労働時間制適用は320時間）」
        <br />
        <br />
        しかし、事業場によっては上記の時間を超える時間外労働が運営上必要なケースがあります。
        <br />
        その場合は、<span>「特別条項」</span>
        付きの36協定を作成することで対応できます。特別条項として下記の範囲で定めることができます。
        <br />
        <br />
        ・「1か月」の時間外労働と休日労働の合計時間が月100時間未満（1年で6回を限度）
        <br />
        ・「1年」の時間外労働が720時間以内（休日労働は除く）
        <br />
        <br />
        また、定めた限度時間にかかわらず、
        <span>
          「時間外労働及び休日労働を合算した時間数は、1か月について100時間未満」
        </span>
        でなければならず、かつ「2か月から6か月までを平均して80時間を経過しないこと」としなければなりません。違反した場合は罰則があります。
      </p>
      <img
        className="img-graph"
        src="/images/column/details/c0013_g01.jpg"
        alt="キンクラコラム"
      />
      <p>
        ※すべての月について、隣接する2～6か月の平均が80時間以内となるようにしなければならない。
      </p>
      <Introduction003 />
      <h1>時間外労働等があったときに支払われる手当と計算</h1>
      <p>
        法定労働時間を超えて時間外労働があった場合や休日労働があった場合は、手当として
        <span>割増賃金</span>を支払わなければなりません。
        <br />
        <br />
        ・時間外労働に対する割増賃金→時間外労働の時間数×1.25
        <br />
        ・休日労働に対する割増賃金→休日労働の時間数×1.35
      </p>
      <img
        className="img-graph"
        src="/images/column/details/c0013_g02.jpg"
        alt="キンクラコラム"
      />
      <p className="indent-back">※例として、1日8時間、土日休みの事業場。</p>
      <p className="indent-back">
        ・水曜日に2時間の時間外労働、また、土曜日（公休）に8時間労働
        <br />
        →通常の賃金×1.25×10時間（時間外労働）=時間外労働の割増賃金額
      </p>
      <p className="indent-back">
        ・日曜日（公休）に8時間労働
        <br />
        →通常の賃金×1.35×8時間（休日労働）=休日労働の割増賃金額
      </p>
      <p className="indent-back bottom-none">
        <span className="bold">Q.</span>
        同じ公休日で、なぜ土曜日は時間外労働で、日曜日が休日労働なのか？
      </p>
      <p className="indent-back">
        <span className="bold">A.</span>
        法定休日は週に1日であり、例では日曜日が法定休日。また、土曜日は法定休日ではないが、週の法定労働時間40時間を超え、時間外労働の対象となる。
      </p>
      <p>
        その他、深夜労働（午後10時～午前5時）に対する割増、1か月の時間外労働が60時間を超えた場合は、その超えた時間については×1.5とする（中小企業は令和5年3月31日まで猶予）割増賃金があります。
      </p>
      <h1>時間外労働等の注意点</h1>
      <p>
        36協定の締結では、<span>労働者の過半数代表労働者の選出</span>
        が必要です。その選出には要件があり、
        <span>
          「管理監督者ではない者」かつ「使用者による指名や使用者の意向による選出ではないこと」
        </span>
        でなければなりません。
        <br />
        36協定の締結をせずに時間外労働等をさせた場合、たとえ割増賃金を支払っていたとしても、労働基準法違反となり罰則があります。
        <br />
        <br />
        建設業など業種によっては限度時間の定めが異なるものもあります。
        <br />
        36協定が適用される事業場の業種の種類に注意しましょう。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        36協定には申請書類が必要なこと、その書類を作成するには労働者過半数代表者の選出が必要なこと、時間外労働の時間には上限があること、違反があった場合は罰則があることと、様々なルールが存在します。
        <br />
        適切に労働時間を管理し、労使トラブルを防止するためにも、書類作成等、不明点がある場合は、労働基準監督署や専門家の社労士への相談し、また
        <span>勤怠システムなどの導入</span>も検討しましょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0013
